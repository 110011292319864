import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Load user on app initialization
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      // Optionally, decode the token to get user details
      setUser({ token }); // Placeholder: You can use a decoded token if needed
    }
  }, []);

  const login = (userData) => {
    // Save token to localStorage
    localStorage.setItem('jwtToken', userData.token);
    // Set user data in state
    setUser(userData);
  };

  const logout = () => {
    // Clear the token and user data
    localStorage.removeItem('jwtToken');
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
