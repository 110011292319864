import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { UserContext } from "./UserContext";
import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 95px)", // Adjusted style
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  button: {
    whiteSpace: "nowrap",
    backgroundColor: "#212121", // Set the background color to dark
    color: "#fff", // Set the text color to white
    "&:hover": {
      backgroundColor: "#212121", // Set the background color on hover
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 150px)", // Set the desired height for the table container
    width: "80%", // Set the desired width for the table container
    margin: "0 auto", // Center the table container horizontally
    overflowY: "scroll", // Add scroll bars when table overflows
    "& th, & td": {
      border: "1px solid #dddddd", // Add border to table cells
      whiteSpace: "nowrap", // Prevent text from wrapping
      overflow: "hidden", // Hide overflowed content
      textOverflow: "ellipsis",
    },
  },
  tableHeaderCell: {
    backgroundColor: "#212121", // Set the background color for the table header cells
    color: "#fff", // Set the text color to white
    fontWeight: "bold",
    fontSize: "1rem",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  pageNumber: {
    backgroundColor: "#fff", // Set background color for unselected page numbers
    color: "#212121", // Set text color for unselected page numbers
    minWidth: "32px", // Adjust as needed
    padding: "4px 8px", // Adjust as needed
    margin: "0 2px", // Adjust as needed
    cursor: "pointer",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#eee", // Set the background color on hover
    },
  },
  selectedPageNumber: {
    backgroundColor: "#212121", // Set background color for selected page number
    color: "#fff", // Set text color for selected page number
    minWidth: "32px", // Adjust as needed
    padding: "4px 8px", // Adjust as needed
    margin: "0 2px", // Adjust as needed
    cursor: "pointer",
    borderRadius: "4px",
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const { user, logout } = useContext(UserContext); // Access the user object from UserContext
  const [formData, setFormData] = useState([]);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [initialRowCount, setInitialRowCount] = useState(20);
  const [recordsToLoad, setRecordsToLoad] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem("jwtToken");

  const fetchData = useCallback(
    async (page = 1, search = "") => {
      if (!token) {
        console.warn("No token found. Skipping protected data fetch.");
        return; // Don't fetch protected data if no token exists
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/formdata`,
          { params: { page, limit: rowsPerPage, search } }
        );
        const { data, totalPages, totalItems } = response.data;
        setFormData(data);
        setTotalPages(totalPages);
      } catch (error) {
        console.error("Error:", error);
        if (error.response?.status === 401 || error.response?.status === 403) {
          // Token invalid or expired, logout and redirect to login
          logout();
        }
        // Handle the error if necessary
      }
    },
    [rowsPerPage, logout, token]
  );
  // useEffect(() => {
  //   // Fetch the form data from the database
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/formdata`
  //       );
  //       const formData = response.data; // Assuming the response contains the form data
  //       setFormData(formData);
  //     } catch (error) {
  //       console.error("Error:", error.message);
  //       // Handle the error if necessary
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData(currentPage, searchQuery);
  }, [fetchData, currentPage, searchQuery]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      // User has scrolled to the bottom of the page
      setInitialRowCount((prevRowCount) => prevRowCount + recordsToLoad);
    }
  }, [recordsToLoad]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleSort = () => {
    setSortingOrder(sortingOrder === "desc" ? "asc" : "desc");
    fetchData(currentPage, searchQuery);
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const filteredFormData = formData?.filter((data) =>
    Object.values(data).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const sortedFormData = filteredFormData?.sort((a, b) => {
    if (sortingOrder === "asc") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });
  // const visibleData = sortedFormData.slice(0, initialRowCount);

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentRows = sortedFormData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPagess = Math.ceil(sortedFormData?.length / rowsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const totalPagesToShow = 5; // Number of page numbers to show
    const halfTotalPagesToShow = Math.floor(totalPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfTotalPagesToShow);
    let endPage = Math.min(totalPagess, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className={classes.root}>
      {user ? ( // Conditionally render the user details table if the user is logged in
        <>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearch}
            style={{ marginBottom: "20px" }}
          />
          <TableContainer component={Paper} className={classes.tableContainer}>
            <div style={{ maxHeight: "500px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeaderCell}>
                      S.No
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      ID
                    </TableCell>
                    <TableCell
                      onClick={handleSort}
                      style={{ cursor: "pointer" }}
                      className={classes.tableHeaderCell}
                    >
                      Registered On
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      First Name
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Family Name
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Company Name
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Country Code
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Mobile No
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Email
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      City
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Nationality
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Job Title
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Department
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Business Nature of Company
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Your Current Industry
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Category
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Products
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Visit Purpose
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Show Info
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Interested
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData?.length > 0 ? (
                    formData?.map((data, index) => (
                      <TableRow key={data.id}>
                        <TableCell>
                          {index + 1 + (currentPage - 1) * rowsPerPage}
                        </TableCell>
                        <TableCell>
                          <a
                            href={`https://register.internationalapparelandtextilefair.com/getuserdetails?userid=${data.id}`}
                            target="_blank"
                          >
                            {data.id}
                          </a>
                        </TableCell>
                        <TableCell>
                          {new Date(data.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{data.firstName}</TableCell>
                        <TableCell>{data.lastName}</TableCell>
                        <TableCell>{data.companyName}</TableCell>
                        <TableCell>{data?.mobileCountryCode}</TableCell>
                        <TableCell>{data.mobileNo}</TableCell>
                        <TableCell>{data.email}</TableCell>
                        <TableCell>{data.city}</TableCell>
                        <TableCell>
                          {data?.nationality ? data?.nationality : "---"}
                        </TableCell>
                        <TableCell>{data.jobTitle}</TableCell>
                        <TableCell>{data.department}</TableCell>
                        <TableCell title={data.businessNature}>
                          {data.businessNature}
                        </TableCell>
                        <TableCell>{data.industry}</TableCell>
                        <TableCell>
                          {Array.isArray(data.category)
                            ? data.category.join(", ")
                            : data.category}
                        </TableCell>
                        <TableCell title={data.products}>
                          {Array.isArray(data.products)
                            ? data.products.join(", ")
                            : data.products}
                        </TableCell>
                        <TableCell>
                          {Array.isArray(data.visitPurpose)
                            ? data.visitPurpose.join(", ")
                            : data.visitPurpose}
                        </TableCell>
                        <TableCell>{data.showInfo}</TableCell>
                        <TableCell>
                          {data.isInterested === 1 ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span style={{ fontSize: "18px" }}>&#60;&#60;</span>
            </Button>
            {pageNumbers?.map((number) => (
              <Button
                key={number}
                variant="contained"
                color="primary"
                size="medium"
                className={
                  number === currentPage
                    ? classes.selectedPageNumber
                    : classes.pageNumber
                }
                onClick={() => paginate(number)}
              >
                {currentPage}
              </Button>
            ))}
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage >= totalPages}
            >
              <span style={{ fontSize: "18px" }}>&#62;&#62;</span>
            </Button>
          </div>
        </>
      ) : (
        // Render the register for event button and welcome message if the user is not logged in
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            We are back with an all new sourcing experience with more than 450
            exhibitors, over a span of 3 days
          </Typography>
          <Typography variant="h5" align="center" gutterBottom>
            20-21-22 MAY 2025
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            <strong>FESTIVAL ARENA - DUBAI</strong>
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            <strong>CONNECT - NETWORK - SOURCE</strong>
          </Typography>
          <div className={classes.buttonContainer}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <Button
                component={Link}
                to="/form"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
              >
                REGISTER FOR THE EVENT
              </Button>
            </motion.div>
          </div>
          <div style={{ marginTop: "25px" }}>
            {/* <Typography variant="subtitle1" align="center" gutterBottom>
              <strong>Halls 6 and 7</strong>
            </Typography> */}
            <Typography variant="subtitle1" align="center" gutterBottom>
              <strong>10 AM - 6 PM</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              gutterBottom
              style={{ color: "#ff0011" }}
            >
              <strong>FREE ENTRY</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              gutterBottom
              style={{ color: "#ff0011" }}
            >
              <strong>1500+ FREE PARKINGS</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              gutterBottom
              style={{ color: "#ff0011" }}
            >
              <strong>EXCLUSIVE BUYERS LOUNGE</strong>
            </Typography>
          </div>
          <div style={{marginTop:'60px'}}>
          <Typography variant="subtitle1" align="center" gutterBottom>
              <strong>Notice for Visitors</strong>
            </Typography>
          <Typography variant="subtitle2" align="justify" gutterBottom style={{maxWidth: '800px', margin: 'auto'}}>
          To maintain the integrity and professionalism of the International Apparel & Textile Fair as a premier B2B platform, we kindly request all visitors to refrain from bringing large bags of samples into the venue. This policy ensures a focused and fair environment for all exhibitors and participants.

Should visitors or potential buyers wish to present samples to exhibitors and manufacturers, we encourage them to arrange a meeting outside the fair’s operating hours, where sample exchanges can take place in a more suitable setting.

We appreciate your understanding and cooperation in upholding the spirit of the event.
          </Typography>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default HomePage;
